
import React, { useEffect }  from 'react';
import { Link } from "react-router-dom";
const pageTitle = 'Spice Global Marketing';

const Footer = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this effect runs only once after initial render


return (
<>
<footer style = {{backgroundColor: "var(--main-color)"}}>
  <div className="container py-5 text-white text-center">
    <div className="row">
      <div className="col-lg-4 col-12 align-self-center">
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/services">Services</Link></li>
          <li><Link to="/location">Location</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </div>
      <div className="col-lg-4 col-12 align-self-center">
        <ul>
          <li><Link to="/privacy">Privacy Policy</Link></li>
          <li><Link to="/cookie">Cookie Policy</Link></li>
          <li><Link to="/terms">Terms and Conditions</Link></li>
        </ul>
      </div>
    
      <div className="col-lg-4 mt-lg-0 mt-4 col-12 align-self-center d-flex justify-content-center">
        <img src="/imgs/fb.png" className="social-img d-block " alt=''/>
        <img src="/imgs/insta.png" className="social-img d-block" alt=''/>   
        <img src="/imgs/tw.png" className="social-img d-block " alt=''/>         
        <img src="/imgs/ytb.png" className="social-img d-block " alt=''/>
        <img src="/imgs/pin.png" className="social-img d-block"alt=''/>
      </div>
    </div>
    <p className="mt-5 text-center copy">
      Copyright <Link to="/" className='bold'>{pageTitle}</Link> 2024
    </p>
  </div>
</footer>
</>
);

}


export default Footer;