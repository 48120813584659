import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link }  from "react-router-dom";
const pageTitle = 'Spice Global Marketing';
function Navigation() {
  return (
    <Navbar collapseOnSelect expand="lg" sticky="top" className="bg-body-tertiary text-focus-in">
      <Container className='px-lg-5'>
        <Navbar.Brand className='bold logo'><Link to='/'>{pageTitle}</Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className='ms-auto'> 
            <Link className='nav-link' to='/services'>Services</Link>
            <Link className='nav-link' to='/location'>Location</Link>
            <Link className='nav-link' to='/member'>Become a Partner</Link>
            <Link className='nav-link' to='/contact'>Contact</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;